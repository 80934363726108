$(function () {
    initPanelOpenClose();

    // mobile submenu navigation
    $('.js-open-submenu').on('click', function (e) {
        e.preventDefault();
        var submenuId = $(this).data('open');
        $('.js-mobile-menu').hide();
        $('.close-offcanvas-menu').hide();
        $('#' + submenuId).addClass('js-submenu-open');
        $('.js-mobile-menu-back').fadeIn();
    });
    $('.js-mobile-menu-back').on('click', function (e) {
        e.preventDefault();
        $('.js-submenu').removeClass('js-submenu-open');
        $('.js-mobile-menu-back').hide();
        $('.js-mobile-menu').fadeIn();
        $('.close-offcanvas-menu').fadeIn();
    });


    // check currency cookies
    function checkCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    if(!checkCookie('currency')) {
        document.cookie = "currency=EUR; path=/";
    }


    // currency and language select
    $('.js-change-language').on('click', function () {
        var languageUrl = $('.js-language-select').val();
        var currency = $('.js-currency-select').val();
        var countrySelect = $('.js-country-select');
        
        document.cookie = "currency=" + currency + "; path=/";
        
        if (countrySelect.data('current') == countrySelect.val()) {
            window.location.href = languageUrl;
        }
        else {
            showQuestionBox(msg['MSG_COUNTRY_CHANGE_COST_WARNING'],
                function() {
                    $.ajax('/restful/carrier/change', {
                        method: 'POST',
                        data: { country_id: countrySelect.val() },
                        dataType: 'json',
                        complete: function() {
                            window.location.href = languageUrl;
                        }
                    });
                }
            );
        }
        
    });
    $('#js-open-language').on('click', function () {
        $('.js-language-menu').slideToggle();
    });


    $('.js-currency-mobile').on('click', function (e) {
        e.preventDefault();
        var currency = $(this).attr('href');
        document.cookie = "currency=" + currency + "; path=/";
        location.reload();
    });


});

function initPanelOpenClose() {
    $('.off-canvas').not('.off-canvas-initialized')
        .on('panel:open', function() {
            const thisPanel = this;
            $('.off-canvas').not(thisPanel).trigger('panel:close');
            $(thisPanel).addClass('is-open').removeClass('is-closed');
            blockPageScroll();
            
            function closeOnOutsideClick(event) {
                if (event.target != thisPanel && $(event.target).closest('.off-canvas').length == 0) {
                    $(thisPanel).trigger('panel:close');
                    $(document.body).off('click', closeOnOutsideClick);
                }
            }
            
            $(document.body).on('click', closeOnOutsideClick);
        })
        .on('panel:close', function() {
            $(this).removeClass('is-open').addClass('is-closed');
            if ($('.off-canvas.is-open').length == 0) {
                restorePageScroll();
            }
        })
        .addClass('off-canvas-initialized');

    $('[data-open-panel]').not('.open-panel-initialized')
        .on('click', function(event) {
            event.stopPropagation();
            const selector = $(this).data('open-panel');
            const target = $(selector);
            target.trigger('panel:open');
        })
        .addClass('open-panel-initialized');

    $('[data-close-panel]').not('.close-panel-initialized')
        .on('click', function(event) {
            event.stopPropagation();
            const selector = $(this).data('close-panel');
            const target = $(selector);
            target.trigger('panel:close');
        })
        .addClass('close-panel-initialized');
}

function blockPageScroll() {
    document.documentElement.classList.add('block-scroll');
}

function restorePageScroll() {
    document.documentElement.classList.remove('block-scroll');
}