$(document).ready(function() {
    'use strict';

    $('#contact-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            // dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
                showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                $('#not-available-email').foundation('close');
                grecaptcha.reset();
            }
        });
    });
});
