$(document).ready(function() {
    $('#user-update-dispatch-form').on('submit', submitForm);
    $('#user-update-invoice-form').on('submit', submitForm);

    function submitForm(event) {
        event.preventDefault();
        const $form = $(this);

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function() {
                $form.find('[type="submit"]').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function() {
                $form.find('[type="submit"]').prop('disabled', false);
            }
        });
    }
});