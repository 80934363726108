

$(document).ready(function () {
    'use strict';

    $('#subscribe-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    if (window.dataLayer) {
                        dataLayer.push({
                            event: "sign_up",
                            eventCallback: function() {
                                sessionStorage.setItem("popup", "close"); // close popup
                                window.location = $form.data('redirectsuccess');
                            }
                        });
                    }
                    else {
                        sessionStorage.setItem("popup", "close"); // close popup
                        window.location = $form.data('redirectsuccess');
                    }
                }
                else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    })


    $('#password-recover-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
                showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
            }
        });
	});
	
	$('#password-new-form-email').validate({

		rules: {
			email: {
				required: true,
				email: true
			}
		},

		messages: {
			email: {
				required: msg['ERR_CLIENTMANDATORYFIELDS'],
				email: msg["ERR_INVALIDMAIL"]
			}
		},

		submitHandler: function (form) {
			var $form = $(form);

			$.ajax({
				method: $form.attr("method"),
				url: $form.attr("action"),
				data: $form.serialize(),
				dataType: "json",
				success: function (data) {
					if (data.success) {
						showMessageBox("success", msg['MSG_RECOVEREMAILSENT']);
						$form.addClass("hide");
						$("#password-new-form-token").removeClass("hide");
						$("#email").val($("#recoveremail").val());
					} else {
						if (data.errors.code == 265) {
							showMessageBox(
								"danger", msg[data.errors.message] || msg['passwordnotcomplex']
							);
						} else {
							showMessageBox(
								"danger", msg[data.errors.message] || msg["MSG_GENERIC_ERROR"]
							);
						}
					}
				},
				error: function () {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				},
			});	
		}

	})

	$.validator.addMethod("complexPassword", function(value, element) {
		return this.optional(element) || value.length >= 8 && /[a-z]/g.test(value) && /[A-Z]/g.test(value) && /[0-9]/g.test(value);
	}, msg['MSG_PASSWORD_NOT_COMPLEX']);


	$('#password-new-form-token').validate({
		rules: {
			token: {
				required: true
			},
			password: {
				required: true,
				complexPassword: true 
			}
		},

		messages: {
			token: {
				required:msg['ERR_CLIENTMANDATORYFIELDS']
			},
			password: {
				required: msg['ERR_CLIENTMANDATORYFIELDS'],
				complexPassword:msg['passwordnotcomplex']
			}
		},

		submitHandler: function (form) {
			var $form = $(form);	

			$.ajax({
				method: $form.attr("method"),
				url: $form.attr("action"),
				data: $form.serialize(),
				dataType: "json",
				success: function (data) {
					if (data.success) {
						showMessageBox('success', msg['MSG_PASSWORD_OK']);
						setTimeout(() => {
							window.location.href = $form.attr('next-url');
						}, 3000);
					} else {
						if (data.errors.message == "fault") {
							showMessageBox("danger", msg["ERR_WRONG_CODE"]);
						} else {
							showMessageBox(
								"danger", msg[data.errors.message] || msg["MSG_GENERIC_ERROR"]
							);
						}
					}
				},
				error: function () {
					showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
				},
			});
		}
	})
    
    if (getUrlParam("token") != undefined) {
		$("#token").val(getUrlParam("token"));
		$("#token").attr("readonly", "readonly");
	}
	if (getUrlParam("email") != undefined) {
		$("#password-new-form-email").addClass("hide");
		$("#password-new-form-token").removeClass("hide");
		$("#recoveremail").val(decodeURIComponent(getUrlParam("email")));
		$("#email").val(decodeURIComponent(getUrlParam("email")));
		$("#email").parents(".label-input").removeClass("hide");
	}

    
});
