$('.giftcard-form-container').on('submit', '#giftcard-form', function(event) {
    event.preventDefault();
    var form = $(this);

    $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize(),
        dataType: 'json',
        success: function(data) {
            if (data.success) {
                showMessageBox('success', msg['MSG_GIFTCARD_VALID']);
            } else {
                showMessageBox('danger', msg['MSG_GIFTCARD_ERROR']);
            }
        },
        failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        }
    });
});

$( document ).ready(function() {
    jQuery('#giftcard_date').pickadate({
        formatSubmit: 'yyyy-mm-dd',
        hiddenName: true,
        min: true,
        max: 60});
});