jQuery(document).ready(function() {
    $('.close-button').on('click', function(event) {
        $(this).closest('.reveal').foundation('close')
    });
});

$('.return-button').on('click', function (e) {
    e.preventDefault();
    $('.reveal').foundation('close');
})

var sidePopup = {

    init: function (popupId, closeButtonId) {
        if ( !sessionStorage.getItem('popup') ) {
            this.open(popupId);

            $(closeButtonId).on('click', function () {
                sidePopup.close(popupId);
            });
        }
    },

    open: function (popupId) {
        $(popupId).show();
    },

    close: function (popupId) {
        $(popupId).hide();
        sessionStorage.setItem("popup", "close");
    }

};