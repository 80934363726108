function isIOS() {
    const iOSPlatforms = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];
    // navigator.platform exists on older iOS
    const platformIsIOS = navigator.platform && iOSPlatforms.includes(navigator.platform);
    return (platformIsIOS) ||
        /* fallback to user agent sniffing */ (/iPad|iPhone|iPod/.test(navigator.userAgent));
}

function showMessageBox(type, message, extra) {
    var boxType = 'alert-' + type;
    var content = '';
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    var messageBox = '<div class="alert ' + boxType + ' fade in">' + message + content + '</div>';
    $('div.alert-box').html(messageBox);
    setTimeout(function () {
        $('div.alert-box').html('');
    }, 5000);
}

function showQuestionBox(message, onConfirm, onCancel = function(){}) {
    const questionBox = $(`
        <div class="question-box-overlay">
            <div class="question-box">
                <div>${message}</div>
                <div class="buttons">
                    <button class="button empty-blue btn-cancel" type="button">${buttons_cancel}</button>
                    <button class="button full-blue btn-confirm" type="button">${buttons_confirm}</button>
                </div>
            </div>
        </div>
    `);
    
    $(document.body).append(questionBox);
    
    $(questionBox.find('.btn-cancel')).on('click', function() {
        onCancel();
        questionBox.remove();
    });

    $(questionBox.find('.btn-confirm')).on('click', function() {
        onConfirm();
        questionBox.remove();
    });

    $(questionBox).on('click', function(event) {
        if (!event.target.matches('.question-box') && !event.target.closest('.question-box')) {
            questionBox.remove();
        }
    });
}

function getUrlParam(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
}

function updateCartCount(url, callback) {
    $('#offCanvasCart').load(url + ' #offCanvasCartContainer', function (responseTxt, statusTxt, xhr) {
        if (statusTxt == 'success' || xhr.status == 404) {
            initPanelOpenClose();
            if (typeof callback !== 'undefined') {
                callback();
            }
            initModCatalog();
        }
        else if (statusTxt == 'error') {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        }
        else {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        }
    });
}

function updateAddToCart(url, callback) {
    $('.addtocart-form-container').load(url + ' .addtocart-form', function () {
        if (typeof callback !== 'undefined') {
            callback();
        }
    });
}

function updateCartQuantity(button, diff, onSuccess) {
    const product = $(button).closest('.cart-product, .off-cart-product');
    const timestamp = Date.now();
    const modelId = product.find('.modelID').val();
    const displayedQuantity = product.find('.orgQuantity').text() >> 0;
    const orgQuantity = product.find('.orgQuantity').data('org') >> 0;
    const maxQuantity = product.find('.maxQuantity').data('max') >> 0;
    let quantity = displayedQuantity + diff;
    quantity = Math.min(quantity, maxQuantity);
    quantity = Math.max(quantity, 0);

    product.data('timestamp', timestamp);
    product.find('.orgQuantity')
        .text(quantity)
        .css('color', '');

    if (quantity != orgQuantity) {
        let timeout = quantity == 0 ? 100 : 600;
        setTimeout(function () {
            if (product.data('timestamp') == timestamp) {
                $('.plus, .minus, .plus-cart, .minus-cart').css('pointer-events', 'none');
                $.ajax({
                    type: 'POST',
                    url: '/restful/shopping/public/checkout/addtocart',
                    data: `qty_model_${modelId}=${quantity}`,
                    dataType: 'json',
                    beforeSend: function () {
                        $('.loader').show();
                    },
                    success: function (data) {
                        if (!data.success) {
                            if (data.code === 'MIXED_INTANGIBLE') {
                                showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                            } else if (data.code === 'MIXED_CART') {
                                showMessageBox('danger', msg['MSG_MIXED_CART']);
                            } else if (data.code === 'LOGIN_REQUIRED') {
                                location.href = '/' + $('html').attr('lang') + '/login';
                            } else {
                                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                            }
                        } else {
                            onSuccess();
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    },
                    complete: function () {
                        $('.loader').hide();

                        if (window.location.href.indexOf('catalog') > 0) {
                            const selected = '#' + $('.shop-product .addtocart-form input:checked').attr('id');
                            updateAddToCart(window.location.href, function () {
                                $('.shop-product .addtocart-form').on('submit', addToCartSubmitHandler);
                            });
                        }

                        initPanelOpenClose();
                    }
                });
            }
        }, timeout);
    }
}

function reloadCatalog(url, push, callback) {
    $.ajax({
        method: 'GET',
        url: url,
        dataType: 'html',
        beforeSend: function () {
            $('#catalog-container').addClass('catalog-loading');
        },
        success: function (data) {
            const response = $('<div>').append(data);

            $('#catalog-wrapper').replaceWith(response.find('#catalog-wrapper'));
            if (push) {
                window.history.pushState(null, document.title, url);
            }
            if (typeof callback !== 'undefined') {
                callback();
            }
        },
        error: function () {
            window.location.href = url;
        },
        complete: function () {
            $('#catalog-container').removeClass('catalog-loading');
        }
    });
}

function subscribeNewsletterCaptcha() {
    $('.subscribe-newsletter button[type=submit]').prop('disabled', false);
}

function removeFromCartPreview (event) {
    event.preventDefault();
    const $this = $(this);
    const product = $this.closest('.off-cart-product, .cart-product');
    let data = {};
    data[$this.data('name')] = 0;
    $.ajax({
        type: 'POST',
        url: $this.data('action'),
        data: data,
        dataType: 'json',
        success: function (data) {
            if (!data.success) {
                if (data.code === 'MIXED_INTANGIBLE') {
                    showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                } else if (data.code === 'MIXED_CART') {
                    showMessageBox('danger', msg['MSG_MIXED_CART']);
                } else if (data.code === 'LOGIN_REQUIRED') {
                    location.href = '/' + $('html').attr('lang') + '/login';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            } else {
                rewixUpdateCart(product, true);
                updateCartCount(window.location.href, function () {
                    if ($this.data('refresh'))
                        location.reload();
                });

                if (window.location.href.indexOf('catalog') > 0) {
                    const selected = '#' + $('.shop-product .addtocart-form input:checked').attr('id');
                    updateAddToCart(window.location.href, function () {
                        $('.shop-product .addtocart-form').on('submit', addToCartSubmitHandler);
                    });
                }
            }
        },
        error: function (xhr, textStatus, errorThrown) {
            showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
        }
    });
}

function initModCatalog() {
    $('.plus').on('click', function () {
        updateCartQuantity(this, 1, () => updateCartCount(window.location.href));
    });
    $('.minus').on('click', function () {
        updateCartQuantity(this, -1, () => updateCartCount(window.location.href));
    });
    $('.button-cart-remove').on('click', removeFromCartPreview);
}

function comeFatto() {
    $('.close-button-come-fatto').on('click tap', function () {
        $('#popup').fadeOut();
    });

    $('.close-come-fatto-popup').on('click tap', function () {
        $('#popup').fadeOut();
    });

    $('#popup').on('click tap', function (e) {
        if ($(e.target).parents('.card').length == 0) {
            $('#popup').fadeOut();
        }
    });

    $('.discover').on('click tap', function () {
        $('#popup').fadeIn();
        $('#popup .content').hide();
        var contentId = $(this).attr('data-content');
        $(contentId).show();
    });
}

/**
 * Disables scrolling of the \<body\> tag.
 */
function blockPageScroll() {
    document.body.classList.add('block-scroll');

    if (isIOS()) {
        const currentScroll = window.scrollY;
        document.documentElement.style.setProperty('height', '100vh');
        document.documentElement.style.setProperty('overflow-y', 'hidden');
        document.body.style.setProperty('height', '100vh');

        document.body.dataset.scroll = currentScroll.toString();
        if (isSafari(15.4)) {
            document.body.scrollTop = currentScroll;
        }
    }
}

/**
 * Re-enables scrolling of the \<body\> tag.
 */
function restorePageScroll() {
    document.body.classList.remove('block-scroll');

    if (isIOS()) {
        document.documentElement.style.removeProperty('height');
        document.documentElement.style.removeProperty('overflow-y');
        document.body.style.removeProperty('height');

        const currentScroll = parseFloat(document.body.dataset.scroll) || 0;
        window.scrollTo({top: currentScroll, behavior: "instant"});
    }
}

/**
 * Returns true if the current browser is Safari (according to its User Agent) with version between the boundaries (inclusive) of `versionRange`, false otherwise.
 * @returns A boolean value
 */
function isSafari(versionCondition) {
    const isSafari =  !!(navigator.vendor) && navigator.vendor.indexOf('Apple') > -1 &&
        !!(navigator.userAgent) &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;

    let versionCheck = true;
    if (typeof versionCondition !== 'undefined') {
        const [_, versionString] = navigator.userAgent.match(/Version\/([0-9]+(\.[0-9]+)?)/);
        const version = parseFloat(versionString);
        
        if (typeof versionCondition === 'number') {
            versionCheck = version >= versionCondition;
        }
        else {
            versionCheck = (version >= versionCondition[0]) && (version <= versionCondition[1]);
        }
    }

    return isSafari && versionCheck;
}

$(document).ready(function () {

    comeFatto();
    var sizeExist = false;

    $('[name="size"]').on('change', function () {
        $('label').each(function () {
            if ($(this).attr('for') == $('input:checked').attr('id')) {
                if (!sizeExist) {
                    $('#size-label').text($('#size-label').text() + ':');
                    sizeExist = true;
                }
                $('#selected-size').text($(this).first().text());
            }
        })
    });
    
    $('.short-description ul li').each(function () {
        const textContent = $(this).text();
        if (textContent.includes(':')) {
            const [option1, option2] = textContent.split(':');
            $(this).html(`<span class="light-grey">${option1.trim()}:</span> ${option2.trim()}`);
        }
    })

    //add cookie check, if not present show message and add cookie
    if ($('#revealPopup').length > 0) {
        if (!checkCookie('popup')) {
            createCookie('popup', 'ok', 365);
            setTimeout(function () {
                $('#revealPopup').foundation('open');
            }, 3000);
        }
    }

    $('.reveal').on(
        'closeme.zf.reveal.zf.reveal', function () {
            $(this).parent().addClass('flex-important');
        }
    );

    $('.reveal').on(
        'closed.zf.reveal.zf.reveal', function () {
            $(this).parent().removeClass('flex-important');
        }
    );

    $('#openPopupHeader').click(function () {
        $('#revealPopup').foundation('open');
    });

    initModCatalog();

    $('.plus-cart').on('click', function () {
        updateCartQuantity(this, 1, () => window.location.reload());
    });
    $('.minus-cart').on('click', function () {
        updateCartQuantity(this, -1, () => window.location.reload());
    });

    $('#profile-consent-form').submit(function (event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents': []
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function (data) {

            },
            success: function (data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
            }
        });
    });

    // slick slider
    $('#slider-home').slick({
        speed: 500,
        fade: true,
        cssEase: 'ease',
        autoplay: true,
        autoplaySpeed: 3500,
        pauseOnFocus: false,
        nextArrow: '<img id="arrow-right" class="slider-home-arrow"  src="/skins/telablu/images/telablu-slider-arrowright.svg" />',
        prevArrow: '<img id="arrow-left" class="slider-home-arrow" src="/skins/telablu/images/telablu-slider-arrowleft.svg" />'
    });

    $('#product-slider').slick({
        speed: 500,
        fade: true,
        cssEase: 'ease',
        pauseOnFocus: false,
        nextArrow: '#arrow-right',
        prevArrow: '#arrow-left'
    });

    $('#related-slider').slick({
        speed: 500,
        fade: true,
        cssEase: 'ease',
        pauseOnFocus: false,
        nextArrow: '#arrow-right-related',
        prevArrow: '#arrow-left-related'
    });
    
    for (const selector of ["#product-slider"]) {
        $(selector)
            .slickLightbox({
                itemSelector: "[data-src]",
                src: function(element) {
                    return $(element).data('src');
                },
                slick: {
                    draggable: false,
                    accessibility: false
                }
            })
            .on({
                'shown.slickLightbox': function() {
                    blockPageScroll();
                    $('.slick-lightbox-slick-item-inner').zoom({
                        magnify: .8
                    });
                },
                'hidden.slickLightbox': function() {
                    restorePageScroll();
                }
            });
    }

    const imageViewer = $('#mobile-image-viewer');
    
    $("#product-slider-mobile .product-slide").on('click', function() {
        const src = this.dataset.src;
        imageViewer.children('img').attr('src', src);
        imageViewer.addClass('open');
        blockPageScroll();
    });

    if (isIOS()) {
        imageViewer.addClass('ios');
        imageViewer.find('.close').on('click', function() {
            imageViewer.removeClass('open');
            restorePageScroll();
        });
    }
    else {
        imageViewer.on('click', function() {
            imageViewer.removeClass('open');
            restorePageScroll();
        });
    }

    if ($('.slider-div').length > 0) {
        $(window).on('scroll', function () {
            if (Foundation.MediaQuery.is('medium up') && $(window).scrollTop() >= 21) {
                $('.slider-div').addClass('fixed');
            }
            else {
                $('.slider-div').removeClass('fixed');
            }
        });
    }


    $('#product-slider-mobile').slick({
        speed: 500,
        fade: true,
        cssEase: 'ease',
        pauseOnFocus: false,
        nextArrow: '#arrow-right-mobile',
        prevArrow: '#arrow-left-mobile'
    });

    $('#category-slider').slick({
        speed: 500,
        fade: true,
        dots: true,
        cssEase: 'ease',
        pauseOnFocus: false,
        nextArrow: '<img id="arrow-right" class="slider-home-arrow"  src="/skins/telablu/images/telablu-slider-arrowright.svg" />',
        prevArrow: '<img id="arrow-left" class="slider-home-arrow" src="/skins/telablu/images/telablu-slider-arrowleft.svg" />'
    });

    $('.category-color-switcher').slick({
        speed: 500,
        fade: true,
        dots: true,
        arrows: false,
        cssEase: 'ease',
        pauseOnFocus: false,
    });

    $('.category-text-slider').slick({
        speed: 500,
        fade: true,
        dots: true,
        cssEase: 'ease',
        nextArrow: '<img id="arrow-right" class="slider-home-arrow"  src="/skins/telablu/images/telablu-slider-arrowright-b.svg" />',
        prevArrow: '<img id="arrow-left" class="slider-home-arrow" src="/skins/telablu/images/telablu-slider-arrowleft-b.svg" />'
    });


    // radio button billing
    if ($('#diff-shipping').prop("checked", false)) {
        $('#billing-address-form-section').css('display', 'none');
        $('#cfpiva').attr('data-abide-ignore', 'data-abide-ignore');
    }

    // reset password
    $('#go-reset').on('click', function () {
        $('#login-block').hide();
        $('#reset-block').fadeIn(1300);
    });

    $('#back-login').on('click', function () {
        $('#reset-block').hide();
        $('#login-block').fadeIn(1300);
    });

    // main filters
    $('#catalog-container').on('click', '.main-filters > a', function (event) {
        event.preventDefault();
        const $this = $(this);
        const target = $this.data('target');
        if ($this.hasClass('main-filters-open')) {
            $this.removeClass('main-filters-open');
            $(target).slideUp();
        }
        else {
            $this.addClass('main-filters-open').siblings().removeClass('main-filters-open');
            $(target).siblings().slideUp();
            $(target).slideDown(200);
        }

        if ($('.main-filters-open:visible').length == 0) {
            $('.close-main-filter').hide();
        }
        else {
            $('.close-main-filter').show(0);
        }
    });

    setupCloseMainFilter();
    function setupCloseMainFilter() {
        $('.close-main-filter').on('click', function () {
            $('.main-filters-open').removeClass('main-filters-open');
            $('.main-filters-dropdown').filter(':visible').slideUp();

            if ($('.main-filters-open:visible').length == 0) {
                $('.close-main-filter').hide();
            }
            else {
                $('.close-main-filter').show(0);
            }
        });
    }

    $('#catalog-container').on('change', 'input.catalog-filter', function (event) {
        event.preventDefault();
        const href = $(this).data('href');
        const id = $('.main-filters-open').attr('id');
        reloadCatalog(href, true, function () {
            if (id) {
                const filter = $('#' + id);
                const target = $(filter.data('target'));
                filter.addClass('main-filters-open');
                target.show(0);
                setupCloseMainFilter();
            }
        });
    });

    // faq dropdown
    $('.faq-item').on('click', function (e) {
        $(this).toggleClass('item-open');
        e.preventDefault();
    });

    // giftcard price dropdown
    $('.giftcard-curr-price').on('click', function (e) {
        e.preventDefault();
        $('.giftcard-options').toggleClass('item-open');
    });


    // newsletter show recapcha
    $('.newsletter-arrow').on('click', function () {
        $(this).parents('.reveal').addClass('input-opened')
        $('.newsletter-step2').fadeIn(1300);
    });

    // newsletter show recapcha
    $('#next-step').on('click', function () {
        $('.step-1').hide();
        $('.step-2').fadeIn(1300);
    });
    $('#prev-step').on('click', function () {
        $('.step-2').hide();
        $('.step-1').fadeIn(1300);
    });


    // profile dropdown
    $.fn.showControls = function () {
        return this.each(function () {
            var $this = $(this);
            var controls = $this.attr('data-target');

            $(controls).addClass('selected');
            $this.addClass('selected');
            $this.find('.order-row-indicator').addClass('fa-rotate-90');
        });
    };

    $.fn.hideControls = function () {
        return this.each(function () {
            var $this = $(this);
            var controls = $this.attr('data-target');

            $(controls).removeClass('selected');
            $this.removeClass('selected');
            $this.find('.order-row-indicator').removeClass('fa-rotate-90');
        });
    };

    $('.order-row').on('click', function (event) {
        var $this = $(this);
        if ($this.hasClass('selected')) {
            $this.hideControls();
        } else {
            // remove selected class from other rows
            $this.parent().find('.order-row.selected').hideControls();
            $this.showControls();
            $('html, body').animate({
                scrollTop: $this.position().top
            }, 200);
        }
    });

    $('.edit-order').on('click', function (event) {
        var $this = $(this);
        $.post('/restful/delegate/easydropshipping/bookedtocart',
            { orderId: $this.attr('data-order-id') },
            function (data, textStatus, jqXHR) {
                if (data.success) {
                    window.location = '/current/cart';
                } else {
                    alert('Cannot edit this order.');
                }
            }
        );
    });

    $('.show-return-form').click(function (event) {
        event.preventDefault();
        var $this = $(this);
        var target = $this.attr('data-target');

        $(target).toggle();
    });

    $('.new-return-request form').submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                $(this).prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RETURN_REQUEST_SUCCESS']);
                } else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                $(this).prop('disabled', false);
            }
        });
    });

    $('form.cancel-return-request-form').submit(function (event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    window.location.href = '/current/profile/returnrequests/1';
                } else {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // smooth scroll
    jQuery('a[href^="#"]').on('click', function (e) {
        e.preventDefault();

        var target = this.hash;
        var dolTarget = jQuery(target);

        if (dolTarget.length) {
            jQuery('html, body').stop().animate({
                'scrollTop': dolTarget.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        }
    });


    // copy link - get friend
    var els_copy = document.querySelectorAll("[data-copy]");
    for (var i = 0; i < els_copy.length; i++) {
        var el = els_copy[i];
        el.addEventListener("submit", function (e) {
            e.preventDefault();
            showMessageBox('success', msg['MSG_LINK_COPIED']);
            var text = e.target.querySelector('input[type="text"]').select();
            document.execCommand("copy");
        });
    }

    var els_selectAll = document.querySelectorAll("[data-click-select-all]");
    for (var i = 0; i < els_selectAll.length; i++) {
        var el = els_selectAll[i];
        el.addEventListener("click", function (e) {
            e.target.select();
        });
    }

    // select custom
    $(".js-select").each(function () {

        var classes = $(this).attr("class"),
            id = $(this).attr("id"),
            name = $(this).attr("name"),
            placeholder = $(this).attr("placeholder");
        var template = '<div class="' + classes + '">';

        template += '<span class="select-trigger">' + placeholder + '</span>';
        template += '<div class="options">';
        $(this).find("option").each(function () {
            template += '<span class="option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
        });
        template += '</div></div>';

        $(this).wrap('<div class="select-wrap"></div>');
        $(this).hide();
        $(this).after(template);
    });

    $(".select-trigger").on("click", function () {
        $(this).parents(".select").toggleClass("opened");
        event.stopPropagation();
    });
    $(".option").on("click", function () {
        var select = $(this).parents(".select-wrap"),
            val = $(this).attr("data-val");
        select.find("select").val($(this).data("value"));
        select.find("option").removeAttr("selected");
        select.find('option[value=' + val + ']').attr("selected", "selected");
        $(this).parents(".select").removeClass("opened");
        $(this).parents(".select").addClass("selected");
        $(this).parents(".select").find(".select-trigger").text($(this).text());
    });
    $('html').on('click', function () {
        $(".select").removeClass("opened");
    });

    $('.shop-product .addtocart-form').on('submit', addToCartSubmitHandler);

    $('.shop-product').on('click', '.product-sizes input.size', function (event) {
        // Update all size input in the product form in order to correctly update cart on 'add-to-cart' action
        const input = $(this);
        // set 0 to all the other values
        input.closest('form').find('input[type=hidden].size-qty-input').each(function () {
            const qtyorig = $(this).data('qtyorig');
            $(this).val(qtyorig);
        });
        // set for the selected input size the value 1
        const target = $(input.data('target'));
        const qtyorig = target.data('qtyorig') >> 0;
        const max = target.data('max') >> 0;
        target.val(qtyorig >= max ? qtyorig : (qtyorig + 1));
    });

    $('.subscribe-newsletter').on('submit', function (event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function (data) {

            },
            success: function (data) {
                if (data.success) {
                    $('.newsletter-step2').hide();
                    $('.mail2').val('');
                    $('.success-message').show();
                }
                else {
                    showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                }
            },
            failed: function (data) {
            },
            complete: function (data) {
                grecaptcha.reset(0);
                grecaptcha.reset(1);
            }
        });
    });

    $('#password-change-form').submit(function (event) {
        event.preventDefault();
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test($("#password").val())) {
            showMessageBox('danger', msg['passwordnotcomplex']);
        } else if ($("#password").val() !== $("#password_confirm").val()) {
            showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
        } else {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $(this).find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    } else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                complete: function (data) {
                    $(this).find(':input').prop('disabled', false);
                }
            });
        }
    });

    // "Com'è fatto" page
    new Swiper('#come-fatto-slider', {
        // Optional parameters
        loop: true,
        speed: 500,
        slidesPerView: 1,
        loopAdditionalSlides: 2,
        spaceBetween: 20,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // Autoplay
        autoplay: {
            delay: 5000,
        },

        // Breakpoints
        breakpoints: {
            640: {
                slidesPerView: 2,
                loopAdditionalSlides: 2,
            },
            1024: {
                slidesPerView: 3,
                loopAdditionalSlides: 2,
            }
        }
    });

    const headerTop = $('.header-top');
    if (headerTop.length > 0) {
        checkIfScrollingHeaderTop();
        $(window).on('resize orientationchange', checkIfScrollingHeaderTop);

        function checkIfScrollingHeaderTop() {
            const wrapper = headerTop.find('.wrapper');
            const wrapperWidth = wrapper.children().toArray().map(el => $(el).innerWidth()).reduce((a, b) => a + b + 23, 0);
            if (wrapperWidth > headerTop.outerWidth()) {
                headerTop.addClass('scroll');
                wrapper.css('--elements-num', wrapper.children().length);
            }
            else {
                headerTop.removeClass('scroll');
            }
        }
    }
});

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function (data) {
                resolve(data.results);
            },
            error: function () {
                resolve([]);
            }
        });
    });
}

function addToCartSubmitHandler(event) {
    event.preventDefault();
    var form = $(this);
    var selected = '#' + $('.product-sizes input:checked').attr('id');

    if (selected === '#no-size-selected') {
        showMessageBox('danger', msg['ERR_NOSIZESELECTED']);
    }
    else {
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            headers: { 'Accept': 'application/json' },
            beforeSend: function (data) {
                $('.loader').show();
            },
            success: function (data) {
                if (!data.success) {
                    if (data.code === 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code === 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    }
                    else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                        showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                    }
                    else if (data.code === 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    }
                    else {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    }
                } else {
                    rewixUpdateCart(form);
                    updateCartCount(window.location.href, function () {
                        $('#offCanvasCart').trigger('panel:open');
                    });

                    if (window.location.href.indexOf('catalog') > 0) {
                        updateAddToCart(window.location.href, function () {
                            $('.shop-product .addtocart-form').on('submit', addToCartSubmitHandler);
                        });
                    }
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            },
            complete: function (data) {
                $('.loader').hide();
            }
        });
    }
}
